import React, { useState, Fragment } from "react";
import Card from "../UI/Card";
import ErrorModal from "../UI/ErrorModal";
import classes from "./FormKiulo.module.css";

const FormKiulo = (props) => {
  const [enteredKiulomeret, setEnteredKiulomeret] = useState("6");
  const [enteredDarab, setEnteredenteredDarab] = useState(1);
  const [enteredAnyag, setEnteredAnyag] = useState("fenyo");
  const [enteredSzin, setEnteredSzin] = useState("mahagoni");
  const [enteredHazhoz, setEnteredHazhoz] = useState("igen");
  const [enteredHazhozKm, setEnteredHazhozKm] = useState(0);
  const [enteredBetonhoz, setEnteredBetonhoz] = useState("igen");
  const [enteredTeto, setEnteredTeto] = useState("trapez");
  const [enteredTetoSzin, setEnteredTetoSzin] = useState("piros");
  const [enteredLakk, setEnteredLakk] = useState("nem");
  const [error, setError] = useState();

  const kiulomeretChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredKiulomeret(event.target.value);
  };

  const DarabChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredenteredDarab(event.target.value);
  };

  const anyagChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredAnyag(event.target.value);
  };

  const szinChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredSzin(event.target.value);
  };

  const hazhozChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredHazhoz(event.target.value);
  };
  const hazhozChangeHandlerKm = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredHazhozKm(event.target.value);
  };
  const betonhozChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredBetonhoz(event.target.value);
  };

  const tetoChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredTeto(event.target.value);
  };

  const tetoSzinChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredTetoSzin(event.target.value);
  };

  const lakkChangeHandler = (event) => {
    // console.log(enteredKiulomeret)
    setEnteredLakk(event.target.value);
  };

  const addKiuloHandler = (event) => {
    event.preventDefault();
    if (enteredDarab < 1) {
      setError({
        title: 'Nem engedélyezet darabszám',
        message: 'Legalább 1 darabot meg kell adnia!',
      });
      console.log(`ez lefut`)
      return;    
    }

    if (enteredHazhoz==="igen" && enteredHazhozKm < 1) {
      setError({
        title: 'Nem állított be távolságot!',
        message: 'A házhozszállítást választotta, de nem adta meg Nagyatádtól mért távolságot.',
      });
      console.log(`ez lefut`)
      return;    
    }
    if (enteredHazhoz==="nem" && enteredHazhozKm > 1) {
      setError({
        title: 'Nem választotta a házhozszállítást!',
        message: 'Megadta Nagyatádtól mért távolságot, de nem választotta a házhozszállítást.',
      });
      console.log(`ez lefut`)
      return;    
    }
    
    props.addKiulo(
      enteredKiulomeret,
      enteredDarab,
      enteredAnyag,
      enteredSzin,
      enteredHazhoz,
      enteredHazhozKm,
      enteredBetonhoz,
      enteredTeto,
      enteredTetoSzin,
      enteredLakk
    );
  };

  const errorHandler = () => {
    setError(null);
  };
  return (
    <div id={classes.form}>
      {error && (
        <ErrorModal
          title={error.title}
          message={error.message}
          onConfirm={errorHandler}
        />
      )}
      <Card className={classes.input} id={classes.input}>
        <form onSubmit={addKiuloHandler}>
          <label htmlFor="kiulomeret">Kiülő méret</label>
          <select
            id="kiulomeret"
            type="text"
            value={enteredKiulomeret}
            onChange={kiulomeretChangeHandler}
          >
            <option type="text" value="6">
              6 személyes
            </option>
            <option type="text" value="8">
              8 személyes
            </option>
            <option type="text" value="10">
              10 személyes
            </option>
            <option type="text" value="12">
              12 személyes
            </option>
          </select>
          <label htmlFor="darab">Hány darabot szeretne?</label>
          <input
            id="darab"
            type="number"
            value={enteredDarab}
            onChange={DarabChangeHandler}
          />
          <label htmlFor="anyag">Anyag</label>
          <select
            id="anyag"
            type="text"
            value={enteredAnyag}
            onChange={anyagChangeHandler}
          >
            <option type="text" value="fenyo">
              Fenyőfa
            </option>
            <option type="text" value="tolgy">
              Tölgyfa
            </option>
          </select>
          <label htmlFor="anyag">Milyen színben kéri?</label>
          <select
            id="szin"
            type="text"
            value={enteredSzin}
            onChange={szinChangeHandler}
          >
            <option type="text" value="fenyo">
              Fenyő
            </option>
            <option type="text" value="tikfa">
              Tikfa
            </option>
            <option type="text" value="dio">
              Dió
            </option>
            <option type="text" value="mahagoni">
              Mahagóni
            </option>
            <option type="text" value="mohazold">
              Mohazöld
            </option>
            <option type="text" value="antracit">
              Antracit
            </option>
            <option type="text" value="paliszander">
              Paliszander
            </option>
          </select>
          <label htmlFor="hazhoz">Házhozszállítást kér?</label>
          <select
            id="hazhoz"
            type="text"
            value={enteredHazhoz}
            onChange={hazhozChangeHandler}
          >
            <option type="text" value="igen">
              Igen
            </option>
            <option type="text" value="nem">
              Nem
            </option>
          </select>
          <label htmlFor="hazhozkm">
            Házhozszálítás esetén Nagyatádtól mért távolság km ben
          </label>
          <input
            id="hazhozkm"
            type="number"
            value={enteredHazhozKm}
            onChange={hazhozChangeHandlerKm}
          />
          <label htmlFor="betonhoz">Betonhoz rögzítést kér?</label>
          <select
            id="betonhoz"
            type="text"
            value={enteredBetonhoz}
            onChange={betonhozChangeHandler}
          >
            <option type="text" value="igen">
              Igen
            </option>
            <option type="text" value="nem">
              Nem
            </option>
          </select>
          <label htmlFor="teto">Milyen tetőt szeretne?</label>
          <select
            id="teto"
            type="text"
            value={enteredTeto}
            onChange={tetoChangeHandler}
          >
            <option type="text" value="trapez">
              Trapézlemez
            </option>
            <option type="text" value="cserep">
              Cserepeslemez
            </option>
          
          </select>
          <label htmlFor="tetoszin">
            Milyen színű tetőt szeretne? <br />
            (Csak cserepeslemez esetén választható)
          </label>
          <select
            id="tetoszin"
            type="text"
            value={enteredTetoSzin}
            onChange={tetoSzinChangeHandler}
          >
            <option type="text" value="barna">
              Barna
            </option>
            <option type="text" value="piros">
              Piros
            </option>
          </select>
          <label htmlFor="lakk">Lakozást szeretne?</label>
          <select
            id="lakk"
            type="text"
            value={enteredLakk}
            onChange={lakkChangeHandler}
          >
            <option type="text" value="igen">
              Igen
            </option>
            <option type="text" value="nem">
              Nem
            </option>
          </select>
          <br />
          <button id={classes.gomb} type="submit">
            Számold ki!
          </button>
        </form>
      </Card>
    </div>
  );
};

export default FormKiulo;
