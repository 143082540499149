import React, { useState, Fragment } from "react";
import Card from "../UI/Card";
import classes from "./KiuloList.module.css";
import kiulo_6_szemelyes from "../../assets/image/kiulo_6_szemelyes.jpg";
import kiulo_8_szemelyes from "../../assets/image/kiulo_8_szemelyes.JPG";
import kiulo_10_szemelyes from "../../assets/image/kiulo_10_szemelyes.jpg";
import kiulo_12_szemelyes from "../../assets/image/kiulo_12_szemelyes.JPG";
import fenyo_szin from "../../assets/image/fenyo_szin.JPG";
import tikfa_szin from "../../assets/image/tikfa_szin.JPG";
import dio_szin from "../../assets/image/dio_szin.JPG";
import mohazold_szin from "../../assets/image/mohazold_szin.JPG";
import antracit_szin from "../../assets/image/antracit_szin.JPG";
import mahagoni_szin from "../../assets/image/mahagoni_szin.JPG";
import paliszander_szin from "../../assets/image/paliszander_szin.JPG";

const KiuloList = (props) => {
  //const hoze = console.log(JSON.stringify(props.kiulok[0]));

  const {
    kiulomeret,
    kiulodarab,
    kiuloanyag,
    kiuloszin,
    kiulohazhoz,
    kiulohazhozkm,
    kiulobetonhoz,
    kiuloteto,
    kiulotetoszin,
    kiulolakk,
  } = props.kiulok[0];

  const szamol = (
    kiulomeret,
    kiulodarab,
    kiuloanyag,
    kiulohazhoz,
    kiulohazhozkm,
    kiulolakk
  ) => {
    let eredmeny;
    /*Méret */
    if (kiulomeret === "6") {
      console.log(`kiulo===6 +120 000 Ft`);

      eredmeny =+ 210000;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "8") {
      console.log(`kiulo===8 +180 000 Ft`);
      eredmeny = +230000;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "10") {
      console.log(`kiulo===10 +250 000 Ft`);
      eredmeny = +250000;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "12") {
      console.log(`kiulo===6 +350 000 Ft`);
      eredmeny = +350000;
      console.log(`eredmeny: ${eredmeny}`);
    }
    /*Darab */
    eredmeny = eredmeny * kiulodarab;

    /*Anyag */
    let kiulotolgy6 = 160000;
    let kiulotolgy8 = 180000;
    let kiulotolgy10 = 120000;
    let kiulotolgy12 = 240000;

    if (kiulomeret === "6" && kiuloanyag === "tolgy") {
      console.log(`let kiulotolgy6 = 30000;`);
      eredmeny = eredmeny + kiulotolgy6;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "8" && kiuloanyag === "tolgy") {
      console.log(`  let kiulotolgy8 = 60000;`);
      eredmeny = eredmeny + kiulotolgy8;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "10" && kiuloanyag === "tolgy") {
      console.log(` let kiulotolgy10 = 120000;`);
      eredmeny = eredmeny + kiulotolgy10;
      console.log(`eredmeny: ${eredmeny}`);
    } else if (kiulomeret === "12" && kiuloanyag === "tolgy") {
      console.log(`    let kiulotolgy12 = 240000;`);
      eredmeny = eredmeny + kiulotolgy12;
      console.log(`eredmeny: ${eredmeny}`);
    }
    /*Házhozszállítás */
    if (kiulohazhoz === "igen") {
      console.log(`kiulohazhoz === "igen"`);
      let hazhotKolts = 200 * kiulohazhozkm;
      eredmeny = eredmeny + hazhotKolts;
      console.log(`eredmeny: ${eredmeny}`);
    }
    /*Lakozás */
    if (kiulolakk === "igen") {
      console.log(`(kiulolakk === "igen")`);
      eredmeny = eredmeny + 60000;
      console.log(`eredmeny: ${eredmeny}`);
    }

    return eredmeny;
  };
  const ar = szamol(
    kiulomeret,
    kiulodarab,
    kiuloanyag,
    kiulohazhoz,
    kiulohazhozkm,
    kiulolakk
  );
  console.log(`az ar: ${ar}`);

  /*
   <div className="row">
          <br /> <br />
          <h3>Végösszeg:</h3>
          <h3>Az ára {ar} Ft</h3>
          <h4>Újraszámoláshoz nyomja meg újra a Számold ki gombot!</h4>
        </div>*/
  return (
    <Fragment>
      <Card>
      <h3  id={classes.cim}>Kerti Kiülő Kalkulator</h3>
        <div className="row">
          <div id={classes.kiulolistKep} className="col-sm-6">
            {kiulomeret === "0" && <img src={kiulo_6_szemelyes} />}
            {kiulomeret === "6" && <img src={kiulo_6_szemelyes} />}
            {kiulomeret === "8" && <img src={kiulo_8_szemelyes} />}
            {kiulomeret === "10" && <img src={kiulo_10_szemelyes} />}
            {kiulomeret === "12" && <img src={kiulo_12_szemelyes} />}
           
           
          </div>
          <div id={classes.kiulolistAdat} className="col-sm-6">
            <h4> {kiulomeret} Személyes Kiülő Méretei:</h4>
            <ul>
              <li> Asztal: 300 cm x 80 cm</li>
              <li> Ülőlapok: 300 cm x 31.5 cm </li>
              <li> Háttámla: 300 cm x 22 cm </li>
              <li> Tető hossza: ~350 cm </li>
              <li> Tető szárny szélessége:~280 cm</li>
              <li> Talp hossza: 250cm</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <br />
          <h3>Megrendelés adatai:</h3>
          <div id={classes.kiulolistAdat} className="col-sm-4">
            <ul>
              <li>
                Méret: {kiulomeret > 0 ? `${kiulomeret} személyes` : "-"}{" "}
              </li>
              <li> Darab: {kiulodarab > 0 ? kiulodarab : "-"} </li>
              <li> Faanyag: {kiuloanyag !== "" ? kiuloanyag : "-"}</li>
              <li> Szin: {kiuloszin !== ""? kiuloszin : "-"}</li>
              <li>Házhozszállítás: {kiulohazhoz !== "" ? kiulohazhoz : "-"} </li>
              <li>
                {" "}
                Nagyatádtól távolság:{" "}
                {kiulohazhozkm !== "" ? `${kiulohazhozkm} km` : "-"}
              </li>
            </ul>
          </div>
          <div id={classes.kiulolistAdat} className="col-sm-4">
            <ul>
              <li>
                {" "}
                Betonhoz rögzítés:{kiulobetonhoz !== "" ? kiulobetonhoz : "-"}
              </li>
              <li> Tető típusa: {kiuloteto !== "" ? kiuloteto : "-"} </li>
              <li> Tető színe: {kiulotetoszin !== "" ? kiulotetoszin : "-"} </li>
              <li> Lakozás: {kiulolakk !== "" ? kiulolakk : "-"}</li>
            </ul>
          </div>
          <div id={classes.kiulolistAdat} className="col-sm-4">
            <h3>Az ár: {ar > 0 ? ar : 0} Ft</h3>
            <h4>Újraszámoláshoz nyomja meg újra a Számold ki gombot!</h4>
          </div>
        </div>
        <div className="row">
         
          <h4>Ki kellett dolgozni hozzá az elektromágnesesség és a quantumgravitáció
            elméletének egyestítését, de meg van: kész a kalkulátor!</h4>
            <div id={classes.egymasalatt}>
           <h4>A Kiülő színe:</h4> 
          {console.log(`kiulotetoszin: ${kiulotetoszin}`)}
            {kiuloszin === "antracit" && <img src={antracit_szin} />}
            {kiuloszin === "mohazold" && <img src={mohazold_szin} />}
            {kiuloszin === "dio" && <img src={dio_szin} />}
            {kiuloszin === "tikfa" && <img src={tikfa_szin} />}
            {kiuloszin === "fenyo" && <img src={fenyo_szin} />}
            {kiuloszin === "mahagoni" && <img src={mahagoni_szin} />}
            {kiuloszin === "paliszander" && <img src={paliszander_szin} />}
            </div>
          
        </div>
      </Card>
    </Fragment>
  );
};

export default KiuloList;
