import React, { useState, Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modelem.module.css";
import FormKiulo from "../FormKiulo/FormKiulo";
import KiuloList from "../KiuloList/KiuloList";

/*
 {ReactDOM.createPortal(
       <KiuloList/>,
        document.getElementById("kiuloList-root")
      )}
*/
const Modelem = (props) => {
 

  const [kiuloList, setKiuloList] = useState([
    {
      kiulomeret: "0",
      kiulodarab: 0,
      kiuloanyag: "",
      kiuloszin: "",
      kiulohazhoz: "",
      kiulohazhozkm: 0,
      kiulobetonhoz: "",
      kiuloteto: "",
      kiulotetoszin: "",
      kiulolakk: "",
    },
  ]);
 

  /*
if(kiuloList.kiulomeret===8){
console.log(`Most mi van? ${kiuloList.kiulomeret}`)
}*/
  const addKiulo = (
    meret,
    darab,
    anyag,
    szin,
    hazhoz,
    hazhozkm,
    betonhoz,
    teto,
    tetoszin,
    lakk
  ) => {
    setKiuloList((prevkiuloList) => {
      let hoze = {
        kiulomeret: meret,
        kiulodarab: darab,
        kiuloanyag: anyag,
        kiuloszin: szin,
        kiulohazhoz: hazhoz,
        kiulohazhozkm: hazhozkm,
        kiulobetonhoz: betonhoz,
        kiuloteto: teto,
        kiulotetoszin: tetoszin,
        kiulolakk: lakk,
      };

      return [hoze, ...prevkiuloList];
    });
/*const hoze = JSON.stringify(kiuloList[0]);
    console.log(`kiuloList[0] ${hoze}`)
    console.log(`kiuloList[0].kiulomeret ${kiuloList[0].kiulomeret}`)*/
  
  };
  return (
    <div id={classes.calculator}>
      <FormKiulo addKiulo={addKiulo} />
      {ReactDOM.createPortal(
        <KiuloList kiulok={kiuloList}  />,
        document.getElementById("kiuloList-root")
      )}
    </div>
  );
};

export default Modelem;
