import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import Modelem from "../Modelem/Modelem";
/*
const Modelem = (props) => {
    return (
    <div>
        <h2>Ide jön a calculator</h2>
    </div>
    );
  };*/


const Calculator = (props) => {
  return (
    <Fragment>
     
      {ReactDOM.createPortal(
        <Modelem />,
        document.getElementById("calculator-root")
      )}
    </Fragment>
  );
};

export default Calculator;
